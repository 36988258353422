<template>

  <section id="ranking">

    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>

    <div class="row my-3">
      <div class="col-12 section-head d-flex align-items-center">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0"> 
          {{ title_component.subtitle }}
        </h3>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-lg-3 mb-3">
        <v-select 
          class="selvue-custom"
          :options="indicadores_list" 
          v-model="indicadores_selected"
          :clearable="false">
        </v-select>
      </div>
      <div class="col-12 col-lg-8 offset-lg-1 mb-3">
        <div class="row">
          <div class="col-12 col-lg-4 col-xl-6 d-flex align-items-center justify-content-lg-end text-secondary">
            <font-awesome-icon 
                icon="filter" 
                class="color-secondary pe-1"
              />
              Filtrar por periodo:
          </div>
          <div class="col-12 col-lg-8 col-xl-6">

            <div class="row">
              <div class="col-6">
                <InputDate 
                  :max="range.date_end"
                  placeHolder="Inicio"
                  @dateEmit="captureDateStart"
                />
              </div>
              <div class="col-6">
                <InputDate 
                  :min="range.date_start" 
                  placeHolder="Término"
                  @dateEmit="captureDateEnd"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Loading v-if="loading" pixeles="150px"/>

    <template v-if="!loading">
    
      <div class="row">
        <div class="col-12">
          <div 
            v-for="(user, i) in ranking" :key="i"
            class="card card-shadow border-round-10 border-0 mb-3">
            <div class="card-body">
              <div class="d-flex align-items-lg-center px-2">
                <div class="flex-shrink-0">
                  <img class="img-obj img-obj-100 img-obj-circle" :src="user_default_img" v-if="!user.imagen_perfil"/>
                  <img class="img-obj img-obj-100 img-obj-circle" :src="user.imagen_perfil" v-if="user.imagen_perfil"/>
                </div>
                <div class="flex-grow-1 ms-3">
                  <div class="row">
                    <div class="col-12 col-lg-6 ps-4">
                      <h6 class="font-title-bold color-secondary mb-1">
                        {{ capitalizeFirstLetter(user.primer_nombre) }}
                        {{ capitalizeFirstLetter(user.apellido_paterno) }}
                      </h6>
                      <p class="color-gray mb-1">{{ user.empresa_cargos.nombre_cargo ? capitalizeFirstLetter(user.empresa_cargos.nombre_cargo) : '' }}</p>
                      <img :src="img" width="32" height="32"/> Total: {{ user.total_reconocimientos }}
                    </div>
                    <div class="col-12 col-lg-6 mt-3 mt-lg-0 d-flex justify-content-end align-items-center">
                      <h4 class="mb-0 d-flex flex-wrap gap-1">
                        <span 
                          v-for="(re, i) in user.reconocimientos_valor" :key="i"
                          class="badge border border-3 border-round-50 font-500" :style="`color: #${value_colors[re.nombre_valor]};`"
                        >
                          {{ re.nombre_valor }} <span class="color-light">{{ re.cantidad }}</span>
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <SinResultados v-if="!loading && ranking.length === 0"/>

  </section>

</template>

<script>
  import SectionTabs from "../Section/SectionTabs.vue";
  import { mapActions } from "vuex";
  import moment from "moment";

  //FF
  import InputDate from "@/components/forms/InputDate.vue"
  import Loading from "@/components/Loading.vue";
  import SinResultados from "@/components/SinResultados.vue";

  export default {
    components: { 
      SectionTabs,

      //FF
      InputDate,
      Loading,
      SinResultados
    },
    data() {
      return {
        tab_component: [
          {
            name: "Dashboard",
            route: "recognize-Dashboard",
          },
          {
            name: "Indicadores",
            route: "recognize-ranking",
          },
        ],
        indicadores_list: [
          { label: "Año", route: "recognize-year" },
          { label: "Gerencia", route: "recognize-management" },
          { label: "Planta", route: "recognize-age" },
          { label: "Top 20", route: "recognize-ranking" },
          { label: "Consultas por colaborador", route: "recognize-contributor" },
          { label: "Detalles de reconocimiento", route: "recognize-reports" },
        ],
        indicadores_selected: "Top 20",

        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        title_component: {
          title: "Reconoce",
          subtitle: "¡Conoce el impacto de Reconoce! Podrás revisar cifras anuales, por gerencia, top 20, lugar de trabajo y colaborador.",
        },
        user_default_img: require("@/assets/img/user_default_img.svg"),
        img: require("@/assets/img/reconoze/ball-star.svg"),
        ranking: [],
        value_colors: {},

        //FF
        loading: true,
        range: {
          date_start: null,
          date_end: null,
          mask_start: null,
          mask_end: null
        }
      };
    },
    
    watch: {
      "range.date_start"() {
        this.getTopYear();
      },
      "range.date_end"() {
        this.getTopYear();  
      },
      indicadores_selected(value) {
        this.$router.push({ name: value.route });
      }
    },

    async created() {
      await this.getValoresColors();
      await this.getTopYear();
    },

    methods: {
      ...mapActions("recognizeModule", ["getValoresAction", "getReconocidosAction"]),

      async getTopYear() {
        this.ranking = [];
        this.loading = true;
        const payload = {
          id_empresa: this.id_empresa,
          fecha_inicio: this.range.date_start !== null ? this.range.mask_start : '',
          fecha_final: this.range.date_end !== null ? this.range.mask_end : moment().endOf('month').format('YYYY-MM-DD'),
        };
        try {
          this.ranking = await this.getReconocidosAction(payload);
          this.ranking.forEach(user => { // Sort by nombre_valor
            user.reconocimientos_valor.sort((a, b) => a.nombre_valor.localeCompare(b.nombre_valor));
          });
        } catch (error) {
          this.toastGenericError();
          console.error(error);
        }
        this.loading = false;
      },

      async getValoresColors() {
        this.value_colors = {};
        try {        
          const res = await this.getValoresAction();
          res.forEach((el) => {
            this.value_colors[el.nombre_valor] = el.color;
          });
        } catch (error) {
          this.toastGenericError();
          console.error(error);
        }
      },

      //FF
      captureDateStart(date) {
        this.range.date_start = date;
        this.range.mask_start = moment(date).format('YYYY-MM-DD');
      },
      captureDateEnd(date) {
        this.range.date_end = date;
        this.range.mask_end = moment(date).format('YYYY-MM-DD');
      }
    },
  };
</script>
